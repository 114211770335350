<script>
import Swal from 'sweetalert2'

import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);
        const doesLoadingOpen = ref(false)

        return {
          doesAddOpen,
          doesEditOpen,
          doesDeleteOpen,
          doesLoadingOpen
        };
    },
    page: {
    title: "Pays",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Pays",
      items: [
        {
          text: "Menu",
        },
        {
          text: "Pays",
          active: true,
        },
      ],
      errors: [],
      moyen: {},
      countries: [],
      moyens: [],
      currencies: [],
      name: "",
      country_id: "",
      currency_id: "",
      account_id: "",
      account_name: "",
      loading: false,
      status: 201,
    };
  },
  methods: {
    loadList() {
      fetch(Helper.route('moyens'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log(response);
          this.status = response.status
          return response.json()
      })
      .then(data => {
        
        this.moyens = data
        this.doesLoadingOpen = false
      })
    },
    loadCountries() {
      fetch(Helper.route('countries'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log(response);
          return response.json()
      })
      .then(data => {
        
        this.countries = data
      })
    },
    loadCurrencies() {
      fetch(Helper.route('currencies'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log(response);
          return response.json()
      })
      .then(data => {
        
        this.currencies = data
      })
    },
    addItem() {
      this.loading = true
      this.errors = []
      let body = {
        "account_id": this.account_id,
        "account_name": this.account_name,
        "currency": {
          "id": this.currency_id
        },
        "country": {
          "id": this.country_id
        },
        "name": this.name
      }
      console.log(body);
      fetch(Helper.route('moyens'), Helper.requestOptionsJSON("POST", body))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        
        this.loading = false
        if (this.status == 201) {
          this.doesAddOpen = false
          this.moyens.push(data) 
          Swal.fire("Moyen ajouter !", "Le moyen a été enregistré avec succès.", "success");
        } else {
            this.errors.name = Helper.getError(data, "moyen_name")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    editItem() {
      this.loading = true
      this.errors = []
      let body = {
        "account_id": this.account_id,
        "account_name": this.account_name,
        "currency": {
          "id": this.currency_id
        },
        "country": {
          "id": this.country_id
        },
        "name": this.name
      }

      fetch(Helper.route('moyens/'+this.moyen.id), Helper.requestOptionsJSON("PUT", body))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        this.loading = false
        if (this.status == 200) {
          this.doesEditOpen = false
          this.moyens = this.moyens.map(i => i.id !== this.moyen.id ? i : data)
          Swal.fire("Moyen modifier !", "Le moyen a été modifié avec succès.", "success");
        } else {
            this.errors.name = Helper.getError(data, "moyen_name")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    deleteIt() {
      this.loading = true
      fetch(Helper.route('moyens/'+this.moyen.id), Helper.requestOptions("DELETE"))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.text()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        if (this.status == 200) {
          this.moyens = this.moyens.filter(i => i.id !== this.moyen.id)
          this.loading = false
          this.moyen = null
          this.doesDeleteOpen = false
          Swal.fire("Moyen supprimer !", "Le moyen a été supprimé avec succès.", "success");
        } else {
          Swal.fire("Moyen non supprimer ?", "La suppression n'a pas marché, veuillez réessayez plus tard.", "error");
        }
      })
    },
    initItem(moyen, action="edit") {
      this.moyen = moyen
      this.initData(moyen)
      if (action == "delete") {
        this.doesDeleteOpen = true
      } else {
        this.doesEditOpen = true
      }
    },
    initData(item = null) {
      this.account_id = item != null ? item.account_id : ""
      this.account_name = item != null ? item.account_name : ""
      this.country_id = item != null ? item?.country?.id : ""
      this.currency_id = item != null ? item?.currency?.id : ""
      this.name = item != null ? item.name : ""
    }
  },
  mounted() {
    this.loadList()
    this.loadCountries()
    this.loadCurrencies()
    this.doesLoadingOpen = false
  },
  watch: {
    doesAddOpen: function() {
      this.errors = []
      if (!this.doesAddOpen) {
        this.initData(null)
      }
    },
    doesEditOpen: function() {
      this.errors = []
      if (!this.doesEditOpen) {
        this.initData(null)
        this.moyen = null
      }
    },
    doesDeleteOpen: function() {
      if (!this.doesDeleteOpen) {
        this.initData(null)
        this.moyen = null
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="hstack mb-3" v-show="status !== 403">
        <b-button variant="primary" @click="doesAddOpen = true" class="ms-auto">
            <i class="bx bx-plus font-size-16 align-middle me-2"></i>
            Ajouter un nouveau
        </b-button>
    </div>
    
    <div class="row" v-show="status !== 403">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des moyens</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>moyens</code>.
            </p>

            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr class="bg-light">
                    <th>#</th>
                    <th>Pays</th>
                    <th>Devise</th>
                    <th>Moyen</th>
                    <th>Account name</th>
                    <th>Account ID</th>
                    <th class="ps-4">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(moyen, i) in moyens" :key="moyen.id">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ moyen?.country?.name }}</td>
                    <td>{{ moyen?.currency?.name }}</td>
                    <td>{{ moyen.name }}</td>
                    <td>{{ moyen.account_name }}</td>
                    <td>{{ moyen.account_id }}</td>
                    <td class="hstack">
                        <b-button class="btn-sm ms-2" @click="initItem(moyen, 'edit')" variant="success">
                            <i class="bx bx-edit font-size-16 align-middle mx-1"></i>
                        </b-button>
                        <b-button variant="danger" @click="initItem(moyen, 'delete')" class="ms-2 btn-sm">
                          <i class="bx bx-trash font-size-16 align-middle mx-1"></i>
                        </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            
          </div>
        </div>
      </div>
    </div>

    

    <div v-show="status === 403">
      <h3 class="text-danger text-center my-5">Réserver à l'administrateur principal</h3>
    </div>


    <!-- LOADING MODAL -->
    <b-modal v-model="doesLoadingOpen" id="modal-center" centered size="md" title="Chargement..."
      title-class="font-18" hide-footer>
      <div class="text-center">
        <div class="spinner-border text-primary-" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </b-modal>

    <!-- ADD MODAL -->
    <b-modal
        v-model="doesAddOpen"
        id="modal-center"
        centered
        size="md"
        title="Ajouter un pays"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="addItem()">
          <div class="row">
            <div class="col-md-12 mb-3">
                <label for="">Sélectionnez le Pays</label>
                <select required v-model="country_id" class="form-control">
                    <option value=""></option>
                    <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                </select>
            </div>
            <div class="col-md-12 mb-3">
                <label for="">Sélectionnez la dévise</label>
                <select required v-model="currency_id" class="form-control">
                    <option value=""></option>
                    <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{ currency.name }}</option>
                </select>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Moyen</label>
              <input type="text" required v-model="name" class="form-control">
              <span class="text-danger mt-2" v-if="errors.name">{{ errors.name }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Account Name</label>
              <input type="text" required v-model="account_name" class="form-control">
              <span class="text-danger mt-2" v-if="errors.account_name">{{ errors.account_name }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Account ID</label>
              <input type="text" required v-model="account_id" class="form-control">
              <span class="text-danger mt-2" v-if="errors.account_id">{{ errors.account_id }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


    <!-- EDIT MODAL -->
    <b-modal
        v-model="doesEditOpen"
        id="modal-center"
        centered
        size="md"
        title="Modifier un moyen"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="editItem()">
          <div class="row">
            <div class="col-md-12 mb-3">
                <label for="">Sélectionnez le Pays</label>
                <select required v-model="country_id" class="form-control">
                    <option value=""></option>
                    <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                </select>
            </div>
            <div class="col-md-12 mb-3">
                <label for="">Sélectionnez la dévise</label>
                <select required v-model="currency_id" class="form-control">
                    <option value=""></option>
                    <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{ currency.name }}</option>
                </select>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Moyen</label>
              <input type="text" required v-model="name" class="form-control">
              <span class="text-danger mt-2" v-if="errors.name">{{ errors.name }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Account Name</label>
              <input type="text" required v-model="account_name" class="form-control">
              <span class="text-danger mt-2" v-if="errors.account_name">{{ errors.account_name }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Account ID</label>
              <input type="text" required v-model="account_id" class="form-control">
              <span class="text-danger mt-2" v-if="errors.account_id">{{ errors.account_id }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesDeleteOpen"
        id="modal-center"
        title="Supprimer un moyen"
        title-class="font-18"
        hide-footer
        >
        <p class="mb-2">
            Voulez-vous vraiment supprimer le moyen <b>{{ moyen?.name }}</b> ?
        </p>
        <div class="hstack mt-2" v-if="!loading">
          <button class="btn btn-dark ms-auto" @click="doesDeleteOpen = false">Annuler</button>
          <button class="ms-3 btn btn-danger" @click="deleteIt()">Supprimer</button>
        </div>
        <div class="text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
    </b-modal>


  </Layout>
</template>
